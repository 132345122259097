import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { baseUrl } from "../../config/config";
import {
  add_teacher_api,
  update_teacher_api,
} from "../../DAL/Teachers/Teachers";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function AddOrUpdateTeacher({
  onCloseDrawer,
  setTeachersList,
  formType,
  selectedObject,
  teachersList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useContentSetting();
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: 0,
    password: "",
    status: true,
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("email", state.email);
    formData.append("phone", state.phone);
    formData.append("status", state.status);
    formData.append("associate", userInfo._id);

    if (image) {
      formData.append("profile_image", image);
    }
    if (formType === "ADD") {
      formData.append("password", state.password);
    }
    const result =
      formType === "ADD"
        ? await add_teacher_api(formData)
        : await update_teacher_api(formData, state._id);
    if (result.code === 200) {
      let new_teacher = result.teacher;
      new_teacher.associate = state.associate;
      new_teacher.table_avatar = {
        src: baseUrl + new_teacher.profile_image,
        alt: new_teacher.name,
      };
      if (formType === "ADD") {
        setTeachersList((teachersList) => [new_teacher, ...teachersList]);
      } else {
        const newState = teachersList.map((obj) => {
          if (obj._id === state._id) {
            return result.teacher;
          }
          return obj;
        });
        setTeachersList(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => {
      return { ...old, [name]: value };
    });
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setState(selectedObject);
      if (selectedObject.profile_image) {
        setPreviews(baseUrl + selectedObject.profile_image);
      }
    }
  }, [formType]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="name"
                label="Name"
                variant="outlined"
                name="name"
                value={state.name}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                type="email"
                id="email"
                label="Email"
                variant="outlined"
                name="email"
                value={state.email}
                required
                onChange={(e) => handleChange(e)}
                autoComplete="new-password"
              />
            </div>

            {formType === "ADD" && (
              <div className="col-12 col-md-6">
                <TextField
                  className="mt-4 form-control"
                  type="password"
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  value={state.password}
                  required={true}
                  onChange={(e) => handleChange(e)}
                  autoComplete="new-password"
                />
              </div>
            )}
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                type="number"
                id="phone"
                label="Phone"
                variant="outlined"
                name="phone"
                value={state.phone}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Status"
                  name="status"
                  className="mui-select"
                  value={state.status}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-12 mt-3 d-flex">
              {previews && (
                <span className="upload-file-preview mt-3">
                  <span onClick={handleRemove}>x</span>
                  <img src={previews} />
                </span>
              )}
              {previews == "" && (
                <span className="upload-button mt-2">
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    id="icon-button-file"
                    style={{ display: "none" }}
                    name="affirmationImage"
                    onChange={handleUpload}
                  />
                  <label htmlFor="icon-button-file">
                    <CloudUploadIcon />
                  </label>
                </span>
              )}
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
