import React, { useState, useEffect } from "react";
import { CircularProgress, TextField, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { baseUrl } from "../../config/config";
import { upload_general_image_api } from "../../DAL/general_routes/general_routes";
import { update_settings_api } from "../../DAL/Users/Users";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function TeacherPortalSetting({}) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo, setUserInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [state, setState] = useState({
    brand_name: "",
    meta_title: "",
    meta_decription: "",
    brand_logo: "",
    share_icon: "",
    favicon: "",
  });

  const handleRemove = (name) => {
    setState((old) => ({ ...old, [name]: "" }));
  };

  const handleUpload = async (event, setting) => {
    const { name, files } = event.target;
    if (files[0]) {
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await upload_general_image_api(formData);
      if (result.code === 200) {
        let image_path = result.image_path;
        setState((old) => ({ ...old, [name]: image_path }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      teacher_portal_setting: state,
    };

    const result = await update_settings_api(formData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setUserInfo((old) => ({ ...old, ...formData }));
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((old) => ({ ...old, [name]: value }));
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.teacher_portal_setting) {
        setState(userInfo.teacher_portal_setting);
      }
      setIsLoadingPage(false);
    }
  }, []);

  if (isLoadingPage) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12">
              <h2 className="mt-4 mb-0">Teacher Portal Setting</h2>
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="brand_name"
                label="Brand Name"
                variant="outlined"
                name="brand_name"
                value={state.brand_name}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                id="meta_title"
                label="Meta Title"
                variant="outlined"
                name="meta_title"
                value={state.meta_title}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-12">
              <TextField
                multiline
                rows={4}
                className="mt-4 form-control"
                id="meta_decription"
                label="Meta Decription"
                variant="outlined"
                name="meta_decription"
                value={state.meta_decription}
                required={true}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12 mt-3 d-flex">
              <Tooltip title="Brand Logo">
                {state.brand_logo ? (
                  <span className="upload-file-preview mt-2">
                    <span
                      onClick={() => {
                        handleRemove("brand_logo");
                      }}
                    >
                      x
                    </span>
                    <img src={baseUrl + state.brand_logo} />
                  </span>
                ) : (
                  <span className="upload-button mt-2 me-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      name="brand_logo"
                      onChange={handleUpload}
                    />
                    <label htmlFor="icon-button-file">
                      <CloudUploadIcon />
                    </label>
                  </span>
                )}
              </Tooltip>
              <Tooltip title="Share Icon">
                {state.share_icon ? (
                  <span className="upload-file-preview mt-2">
                    <span
                      onClick={() => {
                        handleRemove("share_icon");
                      }}
                    >
                      x
                    </span>
                    <img src={baseUrl + state.share_icon} />
                  </span>
                ) : (
                  <span className="upload-button mt-2 me-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file"
                      style={{ display: "none" }}
                      name="share_icon"
                      onChange={handleUpload}
                    />
                    <label htmlFor="icon-button-file">
                      <CloudUploadIcon />
                    </label>
                  </span>
                )}
              </Tooltip>
              <Tooltip title="Favicon">
                {state.favicon ? (
                  <span className="upload-file-preview mt-2">
                    <span
                      onClick={() => {
                        handleRemove("favicon");
                      }}
                    >
                      x
                    </span>
                    <img src={baseUrl + state.favicon} />
                  </span>
                ) : (
                  <span className="upload-button mt-2">
                    <input
                      color="primary"
                      accept="image/*"
                      type="file"
                      id="icon-button-file1"
                      style={{ display: "none" }}
                      name="favicon"
                      onChange={handleUpload}
                    />
                    <label htmlFor="icon-button-file1">
                      <CloudUploadIcon />
                    </label>
                  </span>
                )}
              </Tooltip>
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
