import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../config/config";
import {
  delete_student_api,
  students_list_api,
} from "../../DAL/Students/Students";
import { useNavigate } from "react-router-dom";
// import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import AddOrUpdateStudent from "../../components/Students/AddOrUpdateStudent";
// import ChangePassword from "../../components/Students/ChangePassword";
// import RemainingTimer from "../../components/Students/RemainingTimer";
// import ManageClassTime from "../../components/Students/ManageClassTime";
import { useContentSetting } from "../../Hooks/ContentSetting";
// import StudentFilter from "../../components/Students/StudentFilter";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";
import ChangePassword from "../../components/Students/ChangePassword";
import ManageClassTime from "../../components/Students/ManageClassTime";

export default function StudentsList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { socket, userInfo } = useContentSetting();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenClassTime, setIsOpenClassTime] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [studentsList, setStudentsList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const [openPassword, setOpenPassword] = useState(false);

  const handleOpenPasswordPopup = (data) => {
    setSelectedObject(data);
    setOpenPassword(true);
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setIsOpenClassTime(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_students_list = async () => {
    setIsLoading(true);
    const result = await students_list_api();
    if (result.code === 200) {
      const data = result.students_list.map((student) => {
        return {
          ...student,
          table_avatar: {
            src: baseUrl + student.profile_image,
            alt: student.name,
          },
          whatsapp_number: student.whatsapp ? student.whatsapp : "",
        };
      });
      setStudentsList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    { id: "roll_number_string", label: "Student ID" },
    { id: "name", label: "Name" },
    { id: "gender", label: "Gender", className: "text-capitalize" },
    { id: "birth_date", label: "Birth Date" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
    { id: "guardian_name", label: "Guardian" },
    { id: "address", label: "Address" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "relation", label: "Relation", className: "text-capitalize" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleClosePasswordPopup = () => {
    setOpenPassword(false);
    setSelectedObject({});
  };

  const handleOpenClassTime = (data) => {
    setSelectedObject(data);
    setIsOpenClassTime(true);
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (userInfo.has_school) {
    MENU_OPTIONS.push(
      {
        label: "Manage Class Timings",
        icon: "ion:time-outline",
        handleClick: handleOpenClassTime,
      },
      {
        label: "Change Password",
        icon: "material-symbols:key-outline",
        handleClick: handleOpenPasswordPopup,
      }
    );

    TABLE_HEAD.splice(7, 0, {
      label: "Teachers",
      renderData: (row) => {
        if (row.teachers?.length > 0) {
          return (
            <div>
              {row.teachers.map((teacher) => {
                return <div>{teacher.name}</div>;
              })}
            </div>
          );
        }
      },
    });
  }

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_student_api(selectedObject._id);
    if (result.code === 200) {
      const data = studentsList.filter(
        (student) => student._id !== selectedObject._id
      );
      setStudentsList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_student_online_offline = (data) => {
    setStudentsList((old) => {
      return old.map((student) => {
        if (student._id == data._id) {
          return {
            ...student,
            is_online: data.is_online,
          };
        }
        return student;
      });
    });
  };

  useEffect(() => {
    socket.on("student_online", (data) => {
      handle_student_online_offline(data);
    });
    socket.on("student_offline", (data) => {
      handle_student_online_offline(data);
    });
    return () => {
      socket.off("student_online");
      socket.off("student_offline");
    };
  }, []);

  useEffect(() => {
    get_students_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Students List</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Student</button>
                </div>
              </div>

              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={studentsList}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Student"
        componentToPassDown={
          <AddOrUpdateStudent
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            studentsList={studentsList}
            setStudentsList={setStudentsList}
            selectedObject={selectedObject}
          />
        }
      />

      <CustomDrawer
        isOpenDrawer={isOpenClassTime}
        onOpenDrawer={handleOpenClassTime}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Manage Class Timing"
        componentToPassDown={
          <ManageClassTime
            onCloseDrawer={handleCloseDrawer}
            setStudentsList={setStudentsList}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this student?"}
        handleAgree={handleDelete}
      />

      <ComponentPopup
        openPopup={openPassword}
        setOpenPopup={setOpenPassword}
        title="Change Password"
        componentToPassDown={
          <ChangePassword
            handleClose={handleClosePasswordPopup}
            selectedObject={selectedObject}
          />
        }
      />
    </>
  );
}
