import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import AddOrUpdateTemplates from "../../components/Announcements/AddOrUpdateTemplates";
import {
  announcements_list_api,
  delete_announcements_api,
  user_announcements_list_api,
} from "../../DAL/Announcements/Announcements";
import { useNavigate } from "react-router-dom";

export default function AnnouncementsTemplates() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [announcementsData, setAnnouncementsData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedObject({});
    setFormType("ADD");
  };

  const get_announcements_list = async () => {
    setIsLoading(true);
    const result = await user_announcements_list_api(true);
    if (result.code === 200) {
      setAnnouncementsData(result.announcements);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleDetail = (value) => {
    navigate(`/announcement-templates/detail/${value._id}`, {
      state: value,
    });
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    {
      id: "title",
      label: "Title",
      handleClick: handleDetail,
      className: "cursor-pointer",
    },
    { id: "status", label: "Status", type: "row_status" },
    { id: "action", label: "Action", type: "action" },
  ];

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };
  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Detail",
      icon: "gg:details-more",
      handleClick: handleDetail,
    },
  ];

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_announcements_api(selectedObject._id);
    if (result.code === 200) {
      const data = announcementsData.filter(
        (announcement) => announcement._id !== selectedObject._id
      );
      setAnnouncementsData(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    get_announcements_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Announcements Templates</h2>
                </div>
                <div className="add--button mb-3">
                  <button onClick={handleOpenDrawer}>Add Templates</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={announcementsData}
                MENU_OPTIONS={MENU_OPTIONS}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="Add Templates"
        componentToPassDown={
          <AddOrUpdateTemplates
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            announcementsData={announcementsData}
            setAnnouncementsData={setAnnouncementsData}
            selectedObject={selectedObject}
          />
        }
      />

      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this announcement?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
