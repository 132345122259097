import React, { useState, useEffect } from "react";
import { TextField, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { update_page_content_api } from "../../DAL/WebPages/web_pages";
import GeneralCkeditor from "../GeneralComponents/GeneralCkeditor";
import { upload_general_image_api } from "../../DAL/general_routes/general_routes";
import { baseUrl } from "../../config/config";

export default function UpdatePageContent({
  onCloseDrawer,
  setWebPages,
  formType,
  selectedObject,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});

  const handleRemove = (name) => {
    setState((old) => ({ ...old, [name]: "" }));
  };

  const handleUpload = async (event) => {
    const { name, files } = event.target;
    if (files[0]) {
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await upload_general_image_api(formData);
      if (result.code === 200) {
        setState((old) => {
          return {
            ...old,
            [name]: result.image_path,
          };
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      page_content: state,
    };

    const result = await update_page_content_api(formData, selectedObject._id);
    if (result.code === 200) {
      setWebPages((old) => {
        return old.map((item) => {
          if (item._id == selectedObject._id) {
            return {
              ...item,
              page_content: state,
            };
          }
          return item;
        });
      });
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (selectedObject.page_content) {
      setState(selectedObject.page_content);
    }
  }, [selectedObject]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            {selectedObject.template_attributes_info?.map((field) => {
              if (field.attribute_type == "input") {
                return (
                  <div className="col-12 col-md-6">
                    <TextField
                      className="mt-4 form-control"
                      id={field.attribute_db_name}
                      label={field.attribute_label}
                      variant="outlined"
                      name={field.attribute_db_name}
                      value={state[field.attribute_db_name]}
                      required={field.required}
                      onChange={handleChange}
                    />
                  </div>
                );
              } else if (field.attribute_type == "editor") {
                return (
                  <div className="col-12 mt-3">
                    <h6>{field.attribute_label}</h6>
                    <GeneralCkeditor
                      setInputs={setState}
                      inputs={state}
                      name={field.attribute_db_name}
                      editorHeight={320}
                    />
                  </div>
                );
              }
            })}
          </div>
          <div className="row input-form">
            {selectedObject.template_attributes_info?.map((field) => {
              if (field.attribute_type == "file") {
                return (
                  <div className="col-3 col-md-2 mt-3">
                    {state[field.attribute_db_name] && (
                      <span className="upload-file-preview mt-3">
                        <span
                          onClick={() => {
                            handleRemove(field.attribute_db_name);
                          }}
                        >
                          x
                        </span>
                        <img src={baseUrl + state[field.attribute_db_name]} />
                      </span>
                    )}
                    {!state[field.attribute_db_name] && (
                      <Tooltip title={`Upload ${field.attribute_label}`}>
                        <span className="upload-button mt-2">
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            name={field.attribute_db_name}
                            id={field.attribute_db_name}
                            style={{ display: "none" }}
                            onChange={handleUpload}
                          />
                          <label htmlFor={field.attribute_db_name}>
                            <CloudUploadIcon />
                          </label>
                        </span>
                      </Tooltip>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
