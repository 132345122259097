import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import AccountPopover from "../../components/Header/AccountPopover";
import { useContentSetting } from "../../Hooks/ContentSetting";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import ChatPopover from "../../components/Header/ChatPopover";
import { useLocation } from "react-router-dom";
import { logo } from "../../assets";
import { baseUrl } from "../../config/config";
import DraggableBox from "./CallingBox/DraggableBox";
import { useClassRoom } from "../../Hooks/ClassRoom";

export default function AppHeader({ drawerWidth, handleDrawerToggle }) {
  const { socket, userInfo, setUnreadMessageCount, unreadMessageCount } =
    useContentSetting();
  const { meetingId } = useClassRoom();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const is_class_route = location.pathname.includes("class-room");

  const handle_show_report_notification = (result) => {
    let members = result.members;
    let find_member = members.find((member) => member._id == userInfo._id);
    if (find_member) {
      enqueueSnackbar(result.message, {
        variant: "info",
        autoHideDuration: 25000,
      });
    }
  };

  const showNotification = (data) => {
    let find_student = data.members.find((user) => user.user_type == "student");
    let find_member = data.members.find((member) => member._id == userInfo._id);
    let notification_title = `${find_member.name} messages to ${find_student.name}`;

    let profile_image = logo;
    if (find_student?.profile_image) {
      profile_image = baseUrl + find_student?.profile_image;
    }
    const notification = new Notification(notification_title, {
      body: data.message.message,
      icon: profile_image,
    });
  };

  const getNotificationPermission = (dataObject) => {
    if (Notification.permission === "granted") {
      showNotification(dataObject);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification(dataObject);
        }
      });
    }
  };

  useEffect(() => {
    socket.on("added_report_by_student", (data) => {
      handle_show_report_notification(data);
    });
    if (!is_class_route) {
      socket.on("send_message_from_class_receiver", (data) => {
        let find_user = data.members.find((user) => user._id == userInfo._id);
        if (find_user) {
          getNotificationPermission(data);
          setUnreadMessageCount((old) => old + 1);
        }
      });
    }

    return () => {
      socket.off("added_report_by_student");
      if (!is_class_route) {
        socket.off("send_message_from_class_receiver");
      }
    };
  }, [is_class_route]);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
      className="app-header"
    >
      {meetingId && <DraggableBox />}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <div className="d-flex">
        {unreadMessageCount > 0 && <ChatPopover />}
        <AccountPopover />
      </div>
    </AppBar>
  );
}
