import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { dashboard_stats_api } from "../../DAL/Dashboard/Dashboard";

export default function Dashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const [statsData, setStatsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const get_dashboard_stats = async () => {
    const result = await dashboard_stats_api();
    if (result.code === 200) {
      setStatsData(result);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    get_dashboard_stats();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-user-graduate"></i>
            </div>
            <h3>{statsData.total_students}</h3>
            <p>Students</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-user-graduate"></i>
            </div>
            <h3>{statsData.inactive_students}</h3>
            <p>Inactive Students</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-users"></i>
            </div>
            <h3>{statsData.total_groups}</h3>
            <p>Total Groups</p>
          </div>
        </div>{" "}
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-chalkboard-teacher"></i>
            </div>
            <h3>{statsData.total_teachers}</h3>
            <p>Total Teachers</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fas fa-bullhorn"></i>
            </div>
            <h3>{statsData.announcements}</h3>
            <p>Total Announcements</p>
          </div>
        </div>
        <div className="col-12 col-md-3 mb-3">
          <div className="theme-card p-3 text-center">
            <div className="dashboard-icon-box">
              <i className="fa-solid fa-book"></i>
            </div>
            <h3>{statsData.personal_note}</h3>
            <p>Personal Notes</p>
          </div>
        </div>
      </div>
    </div>
  );
}
