const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = (userInfo) => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Students",
      path: "/students",
      icon: get_icon("fas fa-user-graduate"),
    },
    {
      title: "Extras",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Personal Notes",
          path: "/personal-notes",
          icon: get_icon("fa-solid fa-book"),
        },
        {
          title: "To Do Lists",
          path: "/to-do-lists",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
  ];

  if (userInfo.has_school) {
    sidebarMenus.splice(
      2,
      0,
      {
        title: "Teachers",
        path: "/teachers",
        icon: get_icon("fas fa-chalkboard-teacher"),
      },
      {
        title: "Students Reports",
        icon: get_icon("fa-solid fa-list"),
        child_options: [
          {
            title: "Recent Reports",
            path: "/recent-reports",
            icon: get_icon("fa-solid fa-list"),
          },
          {
            title: "All Reports",
            path: "/students-reports",
            icon: get_icon("fa-solid fa-list"),
          },
        ],
      },
      {
        title: "Class Chat",
        path: "/class-room",
        icon: get_icon("fas fa-users"),
      },
      {
        title: "Training Lesson",
        icon: get_icon("fa-solid fa-globe"),
        child_options: [
          {
            title: "Added By Me",
            path: "/training-lesson",
            icon: get_icon("fa-solid fa-globe"),
          },
          {
            title: "Added By Admin",
            path: "/admin-training-lesson",
            icon: get_icon("fa-solid fa-globe"),
          },
        ],
      },
      {
        title: "Lesson Books",
        path: "/lesson-books",
        icon: get_icon("fas fa-book-reader"),
      },
      {
        title: "Students Groups",
        path: "/students-groups",
        icon: get_icon("fas fa-users"),
      },
      {
        title: "Announcements",
        icon: get_icon("fas fa-bullhorn"),
        child_options: [
          {
            title: "For Me",
            path: "/announcements",
            icon: get_icon("fas fa-bullhorn"),
          },
          {
            title: "Templates",
            path: "/announcement-templates",
            icon: get_icon("fas fa-bullhorn"),
          },
          {
            title: "Announcements",
            path: "/announcements-list",
            icon: get_icon("fas fa-bullhorn"),
          },
        ],
      },

      {
        title: "Pages",
        path: "/web-pages",
        icon: get_icon("fa-regular fa-file-lines"),
      },
      {
        title: "Settings",
        icon: get_icon("fa-solid fa-gear"),
        child_options: [
          {
            title: "Student Portal",
            path: "/student-portal-setting",
            icon: get_icon("fa-solid fa-gear"),
          },
          {
            title: "Teacher Portal",
            path: "/teacher-portal-setting",
            icon: get_icon("fa-solid fa-gear"),
          },
        ],
      }
    );
  } else {
    sidebarMenus.splice(3, 0, {
      title: "Announcements",
      path: "/announcements",
      icon: get_icon("fas fa-bullhorn"),
    });
  }
  return sidebarMenus;
};
export default sidebarConfig;
