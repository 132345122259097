import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  announcements_detail_api,
  seen_announcement_api,
} from "../../DAL/Announcements/Announcements";
import { Link, useLocation, useParams } from "react-router-dom";

export default function AnnouncementDetail() {
  const { enqueueSnackbar } = useSnackbar();
  const { state, pathname } = useLocation();
  const { announcement_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [announcementsData, setAnnouncementsData] = useState({});

  const get_announcement_detail = async () => {
    setIsLoading(true);
    const result = await announcements_detail_api(announcement_id);
    if (result.code === 200) {
      setAnnouncementsData(result.announcement);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    seen_announcement_api(announcement_id);
    if (state) {
      setAnnouncementsData(state);
      window.history.replaceState({}, document.title);
    } else {
      get_announcement_detail();
    }
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-4">
                <div class="back-button-box">
                  <Link to={-1}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">{announcementsData.title}</div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: announcementsData.description,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
