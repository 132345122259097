import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import MUIAutocomplete from "../GeneralComponents/MUIAutocomplete";
import { students_list_api } from "../../DAL/Students/Students";
import {
  add_student_report_api,
  students_prayer_lessons_api,
  update_student_report_api,
} from "../../DAL/StudentReports/StudentReports";
import moment, { max } from "moment";
import { get_min_max_page_value } from "../../utils/constant";
import CustomMUITable from "../GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../config/config";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function AddOrUpdateReport({
  onCloseDrawer,
  setReportsList,
  formType,
  selectedObject,
  reportsList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [studentsList, setStudentsList] = useState([]);
  const [prayerLessonsList, setPrayerLessonsList] = useState([]);
  const [studentPrayerLesson, setStudentPrayerLesson] = useState([]);
  const { userInfo } = useContentSetting();
  const [state, setState] = useState({
    part_mumber: 0,
    page_number: 0,
    line_number: 0,
    prayer_lesson: "",
    report_description: "",
    verse_number: 0,
    lesson_book: "quran",
    status: true,
  });

  const get_students_list = async () => {
    const result = await students_list_api();
    if (result.code === 200) {
      setStudentsList(result.students_list);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const get_students_prayer_lessons = async () => {
    const result = await students_prayer_lessons_api(selectedStudent._id);
    if (result.code === 200) {
      let prayer_lessons = [];
      if (result.prayer_lesson.length > 0) {
        result.prayer_lesson.map((lesson) => {
          prayer_lessons.push({
            ...lesson,
            table_avatar: {
              src: baseUrl + lesson.image,
              alt: lesson.title,
            },
          });
        });
      }
      setPrayerLessonsList(prayer_lessons);
      setStudentPrayerLesson(result.student_prayer_lesson);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let formData = {
      action_by: "teacher",
      lesson_book: state.lesson_book,
      page_number: state.page_number,
      line_number: state.line_number,
      prayer_lesson: studentPrayerLesson,
      report_description: state.report_description,
      student: {
        _id: selectedStudent._id,
        name: selectedStudent.name,
      },
      action_info: {
        _id: userInfo._id,
        name: userInfo.name,
      },
    };
    if (state.lesson_book == "quran") {
      formData.part_mumber = state.part_mumber;
      formData.verse_number = state.verse_number;
    }

    const result =
      formType === "ADD"
        ? await add_student_report_api(formData)
        : await update_student_report_api(formData, state._id);
    if (result.code === 200) {
      let new_report = result.student_report;
      new_report.student_name = new_report.student?.name;
      new_report.quran_part_mumber = new_report.part_mumber
        ? new_report.part_mumber
        : "";
      new_report.quran_verse_number = new_report.verse_number
        ? new_report.verse_number
        : "";
      new_report.action_info_name = new_report.action_info?.name;
      new_report.report_date = moment(new_report.report_date_time).format(
        "DD-MM-YYYY hh:mm A"
      );
      new_report.reference = `${
        new_report.lesson_book == "quran"
          ? "Part (" +
            new_report.part_mumber +
            ") Verse (" +
            new_report.verse_number +
            ")"
          : ""
      }`;
      new_report.lesson_book_name = `${
        new_report.lesson_book == "quran"
          ? "Holy Quran"
          : new_report.lesson_book == "madani_quaida"
          ? "Madani Quadia"
          : "Noorani Quadia"
      } `;

      if (formType === "ADD") {
        setReportsList((reportsList) => [new_report, ...reportsList]);
      } else {
        const newState = reportsList.map((obj) => {
          if (obj._id === state._id) {
            return result.student_report;
          }
          return obj;
        });
        setReportsList(newState);
      }
      onCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeCheckbox = (row, index) => {
    let lessons_list = [...studentPrayerLesson];
    let find_index = lessons_list.findIndex((lesson) => lesson._id == row._id);
    let new_status = 0;
    if (find_index !== -1) {
      let temp_element = { ...lessons_list[index] };

      if (temp_element.status == 0) {
        new_status = 1;
      } else if (temp_element.status == 1) {
        new_status = 2;
      } else if (temp_element.status == 2) {
        new_status = 0;
      }

      temp_element = { ...temp_element, status: new_status };
      lessons_list[index] = temp_element;
      setStudentPrayerLesson(lessons_list);
    } else {
      let lesson_object = {
        _id: row._id,
        status: 1,
      };
      setStudentPrayerLesson((old) => [...old, lesson_object]);
    }
  };

  const handleOpenImage = (row, index) => {
    window.open(baseUrl + row.image);
  };

  const handleChangeStudent = (value) => {
    setSelectedStudent(value);
    setState((old) => {
      return {
        ...old,
        lesson_book: value.lesson_book,
      };
    });
  };

  const TABLE_HEAD = [
    {
      label: "Title",
      alignRight: false,
      renderData: (row, index) => {
        let find_lesson = studentPrayerLesson.find(
          (lesson) => lesson._id == row._id
        );
        let className = "default-checkbox";
        let label_text = "Pending";
        let checked = false;
        if (find_lesson && find_lesson.status == 1) {
          className = "progress-checkbox";
          label_text = "In Progress";
          checked = true;
        } else if (find_lesson && find_lesson.status == 2) {
          label_text = "Completed";
          checked = true;
        }
        return (
          <FormControlLabel
            control={
              <Checkbox
                className={className}
                onChange={() => {
                  handleChangeCheckbox(row, index);
                }}
                checked={checked}
              />
            }
            label={label_text}
          />
        );
      },
    },
    { id: "title", label: "Title", alignRight: false },
    {
      id: "table_avatar",
      label: "Profile",
      alignRight: false,
      type: "thumbnail",
      className: "reduce-cell-padding cursor-pointer",
      handleClick: handleOpenImage,
    },
  ];

  useEffect(() => {
    if (formType == "EDIT") {
      setState(selectedObject);
      setSelectedStudent(selectedObject.student);
    }
  }, [formType]);

  useEffect(() => {
    get_students_list();
  }, []);

  useEffect(() => {
    if (selectedStudent) {
      get_students_prayer_lessons();
    }
  }, [selectedStudent]);

  return (
    <>
      <div className="container new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12 col-md-6 mt-4">
              <MUIAutocomplete
                inputLabel="Student"
                selectedOption={selectedStudent}
                setSelectedOption={handleChangeStudent}
                optionsList={studentsList}
                autoComplete="off"
                name="name"
              />
            </div>
            <div className="col-12 col-md-6">
              <FormControl variant="outlined" className="mt-4 form-control">
                <InputLabel id="demo-simple-select-outlined-label">
                  Lesson Book
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Lesson Book"
                  name="lesson_book"
                  className="mui-select"
                  value={state.lesson_book}
                  disabled
                >
                  <MenuItem value="quran">Holy Quran</MenuItem>
                  <MenuItem value="quaida">Noorani Quaida</MenuItem>
                  <MenuItem value="madani_quaida">Madani Quaida</MenuItem>
                </Select>
              </FormControl>
            </div>
            {state.lesson_book == "quran" && (
              <div className="col-12 col-md-6">
                <TextField
                  className="mt-4 form-control"
                  type="number"
                  id="part_mumber"
                  label="Part Number"
                  variant="outlined"
                  name="part_mumber"
                  value={state.part_mumber}
                  required={true}
                  InputProps={{ inputProps: { min: 1, max: 30 } }}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            )}
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                type="number"
                id="page_number"
                label="Page Number"
                variant="outlined"
                name="page_number"
                value={state.page_number}
                InputProps={{
                  inputProps: {
                    min:
                      state.lesson_book == "quran"
                        ? get_min_max_page_value(state.part_mumber).min
                        : 1,
                    max:
                      state.lesson_book == "quran"
                        ? get_min_max_page_value(state.part_mumber).max
                        : state.lesson_book == "madani_quaida"
                        ? 40
                        : 32,
                  },
                }}
                required
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="col-12 col-md-6">
              <TextField
                className="mt-4 form-control"
                type="number"
                id="line_number"
                label="Line Number"
                variant="outlined"
                name="line_number"
                value={state.line_number}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max:
                      state.lesson_book == "quran"
                        ? 16
                        : state.lesson_book == "madani_quaida"
                        ? 11
                        : 10,
                  },
                }}
                required={true}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {state.lesson_book == "quran" && (
              <div className="col-12 col-md-6">
                <TextField
                  className="mt-4 form-control"
                  type="number"
                  id="verse_number"
                  label="Verse Number"
                  variant="outlined"
                  name="verse_number"
                  value={state.verse_number}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            )}
            <div className="col-12">
              <TextField
                multiline
                rows={5}
                className="mt-4 form-control"
                id="report_description"
                label="Report Description"
                variant="outlined"
                name="report_description"
                value={state.report_description}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div className="col-12 add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </div>
          {selectedStudent && (
            <div className="col-12">
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={prayerLessonsList}
                hide_pagination={true}
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
