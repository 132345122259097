import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { baseUrl } from "../../config/config";
import { students_list_by_teacher_api } from "../../DAL/Students/Students";
import { Link, useParams } from "react-router-dom";
import { useContentSetting } from "../../Hooks/ContentSetting";

export default function StudentsList() {
  const { enqueueSnackbar } = useSnackbar();
  const { teacher_id } = useParams();
  const { socket } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [studentsList, setStudentsList] = useState([]);
  const [teacherInfo, setTeacherInfo] = useState({});

  const get_students_list = async () => {
    setIsLoading(true);
    const result = await students_list_by_teacher_api(teacher_id);
    if (result.code === 200) {
      const data = result.students_list.map((student) => {
        return {
          ...student,
          table_avatar: {
            src: baseUrl + student.profile_image,
            alt: student.name,
          },
          whatsapp_number: student.whatsapp ? student.whatsapp : "",
        };
      });
      setStudentsList(data);
      setTeacherInfo(result.teacher);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "roll_number_string", label: "Student ID" },
    { id: "name", label: "Name" },
    { id: "gender", label: "Gender", className: "text-capitalize" },
    { id: "birth_date", label: "Birth Date" },
    {
      id: "table_avatar",
      label: "Profile",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
    {
      label: "Teachers",
      renderData: (row) => {
        if (row.teachers?.length > 0) {
          return (
            <div>
              {row.teachers.map((teacher) => {
                return <div>{teacher.name}</div>;
              })}
            </div>
          );
        }
      },
    },
    { id: "guardian_name", label: "Guardian" },
    { id: "address", label: "Address" },
    { id: "status", label: "Status", type: "row_status" },
    { id: "relation", label: "Relation", className: "text-capitalize" },
  ];

  const handle_student_online_offline = (data) => {
    setStudentsList((old) => {
      return old.map((student) => {
        if (student._id == data._id) {
          return {
            ...student,
            is_online: data.is_online,
          };
        }
        return student;
      });
    });
  };

  useEffect(() => {
    socket.on("student_online", (data) => {
      handle_student_online_offline(data);
    });
    socket.on("student_offline", (data) => {
      handle_student_online_offline(data);
    });
    return () => {
      socket.off("student_online");
      socket.off("student_offline");
    };
  }, []);

  useEffect(() => {
    get_students_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div class="back-button-box">
                  <Link to={`/teachers`}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">
                  {teacherInfo.name + " (" + teacherInfo.email + ")"}
                </div>
              </div>
              <div className="light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Students List</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={studentsList} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
