import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { baseUrl } from "../../config/config";
import { useClassRoom } from "../../Hooks/ClassRoom";
import { Icon } from "@iconify/react";

export default function UserCard({ selectedConversation }) {
  const { meetingId, handleStartCall } = useClassRoom();

  let conversations_user = selectedConversation?.members.find(
    (user) => user.user_type == "student"
  );

  return (
    <div className="user-card">
      {selectedConversation && (
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Avatar
              sx={{ width: 45, height: 45 }}
              src={baseUrl + conversations_user?.profile_image}
            >
              {conversations_user?.name[0]}
            </Avatar>
            <div className="user-name mt-2 ms-2">
              <h6>{conversations_user?.name}</h6>
            </div>
          </div>
          {!meetingId && (
            <div className="call-buttons me-2 mt-1">
              <Tooltip title="Start Call">
                <div
                  className="start-call-box audio-call"
                  onClick={() => {
                    handleStartCall(selectedConversation._id);
                  }}
                >
                  <Icon
                    fontSize="18"
                    className="start-call-icon"
                    icon="ion:call-outline"
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
